*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 16px;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
  background-color: var(--backgroundColor);
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

#__next {
  height: 100%;
}

.ant-layout {
  height: 100%;
}

.fullscreen-loader {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}